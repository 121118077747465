import React, { useState } from "react";
import { auth } from "../firebase";
import { Button, TextField, Box, Typography, CircularProgress } from "@mui/material";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const LoginForm = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [step, setStep] = useState(0);
  const [loader, setLoader] = useState(false);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,9}$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handleLogin = async () => {
    const formattedPhoneNumber = `+966${phoneNumber}`;
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );

    try {
      setLoader(true)
      const result = await signInWithPhoneNumber(
        auth,
        formattedPhoneNumber,
        recaptchaVerifier
      );
      setConfirmationResult(result);
      setStep(1);
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.error("Error during sign-in:", error);
    }
  };

  const handleVerify = async () => {
    setLoader(true)
    try {
     
      await confirmationResult.confirm(verificationCode);
      setStep(2);
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.error("Error verifying code:", error);
    }
  };
  return (
    <Box>
    {loader && <Box display="flex" alignItems="center" justifyContent="center">
    <CircularProgress />
  </Box>}
      {step === 0 && (
        <Box>
          <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
            Login
          </Typography>

          <TextField
            fullWidth
            label="Phone Number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Enter 9 digits"
            sx={{ mt: 2 }}
          />
          <Button
            fullWidth
            onClick={handleLogin}
            variant="contained"
            color="primary"
            sx={{ mt: 2, padding: 2 ,color:'white',fontWeight:'bold'}}
          >
            Send Verification Code
          </Button>
          <div id="recaptcha-container"></div>
        </Box>
      )}
      {step === 1 && (
        <>
          <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
            Verify Code{" "}
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ textAlign: "center" }}>
            {" "}
            SMS has been sent to +966{phoneNumber}
          </Typography>

          <TextField
            fullWidth
            label="Verification Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Button
            fullWidth
            onClick={handleVerify}
            variant="contained"
            color="primary"
            sx={{ mt: 2, padding: 2 ,color:'white',fontWeight:'bold'}}
          >
            Verify Code
          </Button>
        </>
      )}
    </Box>
  );
};

export default LoginForm;
