import React from 'react';
import { Box, Typography, Chip } from '@mui/material';

const MyIdeasList = ({ ideas, user }) => {
  const userIdeas = ideas.filter(idea => idea.userId === user.uid);

  const getStatusColor = (selected,status) => {
    if(selected){
      return 'blue'
    }
    switch (status) {
  
      case 'approved':
        return 'green';
      case 'pending':
        return 'orange';
      case 'rejected':
        return 'red';
      default:
        return 'grey';
    }
  };

  return (
    <div style={{width:"100%"}}>
      {userIdeas.map((idea) => (
        <Box key={idea.id} sx={{ mb: 2, p: 2, border: '1px solid #ccc', borderRadius: '8px', width: '100%',overflowWrap:'break-word'}}>
        <Typography variant="h6">{idea.name}</Typography>
          <Typography>{idea.description}</Typography>
          <Typography>Budget: {idea.budget}</Typography>
          <Chip
            label={idea.selectedBy ?"Selected":idea.status}
            style={{ backgroundColor: getStatusColor(idea.selectedBy,idea.status), color: 'white' }}
          />
          {idea.rejectionReason && idea.status === 'rejected' && (
            <Typography color="error">Rejection Reason: {idea.rejectionReason}</Typography>
          )}
        </Box>
      ))}
    </div>
  );
};

export default MyIdeasList;
