import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

const NotificationsPopup = ({ user }) => {
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      const notificationsSnapshot = await db
        .collection("notifications")
        .where("userId", "==", user?.uid)
        .where("read", "==", false)
        .get();
      const notificationsData = notificationsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      if (notificationsData.length > 0) {
        setNotifications(notificationsData);
        setOpen(true);
      }
    };

    fetchNotifications();
  }, [user]);

  const handleClose = async () => {
    const batch = db.batch();
    notifications.forEach((notification) => {
      const notificationRef = db
        .collection("notifications")
        .doc(notification.id);
      batch.update(notificationRef, { read: true });
    });
    await batch.commit();
    setOpen(false);
    setNotifications([]);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>🔔 Notifications</DialogTitle>
      <DialogContent>
        {notifications.map((notification, index) => (
          <Typography key={index}>{notification.message}🍆💦</Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationsPopup;
