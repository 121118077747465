import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Divider,
} from "@mui/material";

const LogsList = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLogs = async () => {
      setLoading(true);
      const logsSnapshot = await db.collection("logs").orderBy("date", "desc").get();
      const logsData = logsSnapshot.docs.map((doc) => doc.data());
      setLogs(logsData);
      setLoading(false);
    };

    fetchLogs();
  }, []);

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Notifications
      </Typography>
      <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
        <List>
          {logs.map((log, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemText primary={log.logEntry} secondary={new Date(log.date).toLocaleString()} />
              </ListItem>
              {index < logs.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default LogsList;
