import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { KeyboardReturnOutlined } from "@mui/icons-material";
import CardSelector from "./CardSelector";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  query,
  collection,
  where,
  getDocs,
  doc,
  setDoc,
  updateDoc,

  arrayUnion,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import img from "../../src/pack.jpeg";
import logo from "../../src/logo.jpeg";

const avoidApplyList = ["3", "5", "6"];

const CardList = ({ user }) => {
  const [transactionId, setTransactionId] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("pending");
  const [cards, setCards] = useState([]);
  const [packages, setPackages] = useState([]);
  const [pointsList, setPointsList] = useState([]);
  const [userPackages, setUserPackages] = useState([]);
  const [userCards, setUserCards] = useState([]);
  const [userPoints, setUserPoints] = useState(0);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedPackageCard, setSelectedPackageCard] = useState(null);

  const [log, setLog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isTransfer, setIsTransferd] = useState(false);

  const [paymentPending, setpaymentPending] = useState(false);
  const [paymentPendingUrl, setPaymentPendingUrl] = useState(false);

  const [visiblePackages, setVisiblePackages] = useState({});

  const handleTogglePackage = (packageNumber) => {
    setVisiblePackages((prevState) => ({
      ...prevState,
      [packageNumber]: !prevState[packageNumber], // Toggle the visibility for the specific package
    }));
  };

  const handelPendingPayment = () => {
    window.location.href = paymentPendingUrl;
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const auth = getAuth();

  const handlePayment = async (price, point) => {
    try {
      setLoading(true);

      const functions = getFunctions();

      const handlePaymentFunction = httpsCallable(functions, "handlePayment");

      const userId = auth.currentUser.uid;

      const result = await handlePaymentFunction({ points: price });
      console.log(result);
      const paymentUrl = result.data.mobileUrl;
      const transactionId = result.data.transactionNo; // Ensure transactionId is included in the response

      // Store the transaction in Firestore
      await setDoc(doc(db, "userPayments", transactionId), {
        userId,
        transactionId,
        status: "pending",
        paymentUrl,
        points: point,
        price,
      });

      // Redirect to the payment URL
      window.location.href = paymentUrl;

      // Store transactionId in state for further status checking
      setTransactionId(transactionId);
    } catch (error) {
      setLoading(false);
      console.log(error);

      console.error("Payment error:", error);
      alert("Payment failed. Please try again.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
       checkPaymentStatus()
      const userDoc = await db.collection("users").doc(user.uid).get();
      if (userDoc.exists) {
        const userData = userDoc.data();
        setUserPoints(userData.points || 0);
        setUserCards(userData.cards || []);
        setUserPackages(
          userData?.packageCount
            ? Array.from({ length: userData.packageCount }, (_, i) => i + 1)
            : []
        );
      }

      const cardsSnapshot = await db.collection("cards").get();
      const cardsData = cardsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCards(cardsData);

      const packageSnapshot = await db.collection("packages").get();
      const packageData = packageSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPackages(packageData);

      const pointsListSnapshot = await db.collection("pointsList").get();
      const pointsData = pointsListSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setPointsList(pointsData);

      const usersSnapshot = await db.collection("users").get();
      const usersData = usersSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((userDoc) => userDoc.id !== user.uid);
      setUsers(usersData);

      const logsSnapshot = await db
        .collection("logs")
        .where("userId", "==", user.uid)
        .get();
      const logsData = logsSnapshot.docs.map((doc) => doc.data().logEntry);
      setLog(logsData);
      setLoading(false);
    };

    fetchData();
  }, [user]);

  const checkPaymentStatus = async () => {
    try {
      const userId = auth.currentUser.uid;

      // Query the userPayments collection for pending transactions
      const q = query(
        collection(db, "userPayments"),
        where("userId", "==", userId),
        where("status", "==", "pending")
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return;
      }

      // Assuming there is only one pending transaction, otherwise you can handle multiple pending transactions
      const pendingTransaction = querySnapshot.docs[0];

      const transactionId = pendingTransaction.id;

      const functions = getFunctions();
      const checkPaymentStatusFunction = httpsCallable(
        functions,
        "checkPaymentStatus"
      );

      const result = await checkPaymentStatusFunction({ transactionId });
      const status = result.data.orderStatus;

     

      if (status !== "Paid" ) {

        setpaymentPending(true);
        
      }
      if (status === "Paid") {
        const userRef = doc(db, "users", userId);
    
        const userDoc = await db.collection("users").doc(user.uid).get();
        const userData = userDoc.data();
        const transactionData = pendingTransaction.data();
        const pendingPoints = transactionData.points;

        await updateDoc(userRef, {
          points:userData.points +pendingPoints, // You can increment or set the count based on your logic
        });
  

        // Update payment status in Firestore
        await updateDoc(doc(db, "userPayments", transactionId), {
          status,
        });
      }

      setPaymentStatus(status);
    } catch (error) {
      console.error("Error checking payment status:", error);
    }
  };



  const handleBuyCard = async (card) => {
    if (userPoints >= card.points) {
      const newPoints = userPoints - card.points;
      const newUserCards = [...userCards, card];

      setUserPoints(newPoints);
      setUserCards(newUserCards);

      await db.collection("users").doc(user.uid).update({
        points: newPoints,
        cards: newUserCards,
      });

      const userDoc = await db.collection("users").doc(user.uid).get();
      const userData = userDoc.data();

      await db.collection("logs").add({
        userId: user.uid,
        cardId: card.id,
        action: "purchased",
        date: Date.now(),
        logEntry: `${userData.nickname} purchased  ${card.name} card 🌚`,
      });
      alert("You are ready to fuck someone");
    } else {
      alert("You don't have enough points to buy this card.");
    }
  };

  const handleClosePackage = async (value, card) => {
    setSelectedPackageCard(card);
    setLoading(true);
    // Close the package dialog
    setVisiblePackages((prev) => ({ ...prev, [value]: false }));
    setIsDialogOpen(true);
    // Update the userPackages array and add the selected card
    setUserPackages((prevPackages) =>
      prevPackages.filter((pkg) => pkg !== value)
    );
    const newUserCards = [...userCards, card];
    const updatedPackageCount = [userPackages.length - 1];

    await db.collection("users").doc(user.uid).update({
      cards: newUserCards,
      packageCount: updatedPackageCount,
    });

    setUserCards(newUserCards);

    const userDoc = await db.collection("users").doc(user.uid).get();
    const userData = userDoc.data();

    await db.collection("logs").add({
      userId: user.uid,
      cardId: card.id,
      action: "package",
      date: Date.now(),
      logEntry: `${userData.nickname} got  ${card.name} card in package 📦`,
    });

    setLoading(false);
    // Update the selected card (could be used for display elsewhere)
  };

  const handleApplyCard = (card, transfer) => {
    if (transfer) {
      setIsTransferd(true);
    } else {
      setIsTransferd(false);
    }

    setSelectedCard(card);
    setOpen(true);
  };

  const handleConfirmApply = async () => {
    const selectedUserNow = selectedUser ? selectedUser : "1";
    if (selectedCard && selectedUserNow) {
      const cardIndex = userCards.findIndex(
        (card) => card.id === selectedCard.id
      );
      if (cardIndex !== -1) {
        const newUserCards = [...userCards];
        newUserCards.splice(cardIndex, 1); // Remove only one instance of the card
        setUserCards(newUserCards);

        await db.collection("users").doc(user.uid).update({
          cards: newUserCards,
        });
      }

      // Fetch the current user's nickname
      const currentUserRef = db.collection("users").doc(user.uid);
      const currentUserDoc = await currentUserRef.get();
      const currentUserData = currentUserDoc.data();
      const currentUserPoints = currentUserData.points || 0;

      // Fetch the affected user's nickname
      const affectedUserRef = db.collection("users").doc(selectedUserNow);
      const affectedUserDoc = await affectedUserRef.get();
      const affectedUserData = affectedUserDoc.data();
      const affectedUserPoints = affectedUserData.points || 0;

      if (selectedCard.id === "16") {
        await currentUserRef.update({
          points: 0,
        });

        await affectedUserRef.update({
          points: affectedUserPoints + currentUserPoints,
        });
      }

      if (isTransfer) {
        await affectedUserRef.update({
          cards: arrayUnion(selectedCard),
        });
      }

      const logEntry = `${currentUserData.nickname} ${
        isTransfer ? "Transfer" : "applied"
      } ${selectedCard.name} card on ${affectedUserData.nickname} 🔥🔥🔥🔥`;
      setLog([...log, logEntry]);

      console.log(logEntry);
      await db.collection("logs").add({
        userId: user.uid,
        cardId: selectedCard.id,
        appliedToUserId: selectedUser,
        appliedToUserNickname: affectedUserData.nickname,
        date: Date.now(),
        logEntry: logEntry,
      });

      // Add notification for the affected user
      await db.collection("notifications").add({
        userId: selectedUser,
        message: `A card ${selectedCard.name} was ${
          isTransfer ? "Transfer" : "applied"
        } to you by ${currentUserData.nickname}.`,
        read: false,
        date: Date.now(),
      });

      setOpen(false);
      setSelectedCard(null);
      setSelectedUser("");
      setIsTransferd(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const users1 = users.filter((user) => user.id !== "1");

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Buy Points
      </Typography>
      <Grid container spacing={3}>
        {pointsList.map((point) => (
          <Grid item xs={16} sm={6} md={4} lg={3} key={point.id}>
            <Paper
              sx={{
                backgroundImage: `url(${logo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                position: "relative",
                overflow: "hidden",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: "10px",
                  padding: "35px",
                  color: "white",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {!paymentPending && (
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {point?.name}
                  </Typography>
                )}

                {paymentPending && (
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    You need to pay the pending payment to buy from {" "}
                    <a
                      href={"https://google.com"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Here
                    </a>
                  </Typography>
                )}

                {!paymentPending && (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ color: "white", fontWeight: "bold" }}
                    onClick={() => handlePayment(point?.price, point.points)}
                  >
                    Buy Now ({point.price} SAR)
                  </Button>
                )}
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Typography variant="h4" gutterBottom>
        Card List
      </Typography>
      <Grid container spacing={3}>
        {cards.map((card) => (
          <Grid item xs={16} sm={6} md={4} lg={3} key={card.id}>
            <Paper
              sx={{
                backgroundImage: `url(${card.url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                position: "relative",
                overflow: "hidden",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: "10px",
                  padding: "20px",
                  color: "white",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {card.name}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {card.description}
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ color: "white", fontWeight: "bold" }}
                  onClick={() => handleBuyCard(card)}
                >
                  Buy Now ({card.points} points)
                </Button>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Available Cards
      </Typography>
      <List>
        {userCards.map((card) => (
          <ListItem key={card.id}>
            <ListItemText
              primary={card.name}
              secondary={`Points: ${card.points}`}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ marginRight: 1 }}
              onClick={() => handleApplyCard(card, true)}
            >
              Transfer
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleApplyCard(card, false)}
            >
              Apply
            </Button>
          </ListItem>
        ))}
      </List>

      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Available Packages ({userPackages.length}x)
      </Typography>
      <List>
        <Grid container spacing={3}>
          {userPackages.map((value) => (
            <Grid item xs={16} sm={6} md={4} lg={3} key={value}>
              {!visiblePackages[value] && (
                <Paper
                  sx={{
                    backgroundImage: `url(${img})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "10px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    position: "relative",
                    overflow: "hidden",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "10px",
                      padding: "20px",
                      height: "200px",
                      color: "white",

                      position: "relative", // Make the parent container relatively
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {!visiblePackages[value] && (
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          position: "absolute", // Position the button absolutely within the parent
                          bottom: "10px", // Adjust this value to control spacing from the bottom
                          left: "50%", // Position the button horizontally in the center
                          transform: "translateX(-50%)", // Corrects the centering by shifting it left by 50% of its own width
                        }}
                        onClick={() => handleTogglePackage(value)}
                      >
                        Open Package
                      </Button>
                    )}
                  </Box>
                </Paper>
              )}

              {visiblePackages[value] && (
                <CardSelector
                  cardsWithProbability={cards}
                  onClose={(selectedCard) =>
                    handleClosePackage(value, selectedCard)
                  } // Pass the close handler
                />
              )}
            </Grid>
          ))}
        </Grid>
      </List>
      <Dialog
        fullWidth
        open={open}
        onClose={() => {
          setOpen(false);
          setIsTransferd(false);
        }}
      >
        <DialogTitle>Apply Card</DialogTitle>
        <DialogContent>
          {isTransfer && (
            <Typography variant="h7" gutterBottom sx={{ mt: 4 }}>
              Once applied the card will be automatically transferred to
              the selected user
            </Typography>
          )}
          {!avoidApplyList.includes(selectedCard?.id) ||isTransfer  ? (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="select-user-label">Select User</InputLabel>
              <Select
                labelId="select-user-label"
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                {users1.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.nickname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
              Once you apply it please notify top management
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmApply} color="secondary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isDialogOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          You have received a {selectedPackageCard?.name} Card!
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={selectedPackageCard?.url}
              alt={selectedPackageCard?.name}
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "8px",
                marginTop: "16px",
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button variant="contained" color="primary" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CardList;
