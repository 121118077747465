import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Container,
  Paper,
  Typography,
  Button,
  Tabs,
  Tab,
  Box,
  Grid,
  IconButton,
  TextField,
  CircularProgress,
  Divider,
} from "@mui/material";
import { auth, db } from "./firebase";
import {
  query,
  collection,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import LoginForm from "./components/LoginForm";
import IdeaForm from "./components/IdeaForm";
import IdeaList from "./components/IdeaList";
import ThemeToggle from "./components/ThemeToggle";
import "./App.css"
import PendingIdeaList from "./components/pendingIdeaList.js";
import MyIdeasList from "./components/myIdeas.js";
import LogsList from "./components/LogList.js";
import Leaderboard from "./components/Leaderboard.js";
import NotificationsPopup from "./components/NotificationsPopup.js";
import UserActions from "./components/userAction.js";
import { getFunctions, httpsCallable } from "firebase/functions";

import CardList from "./components/CardList";
import {
  DarkModeOutlined,
  ExitToAppOutlined,
  Image,
  InfoOutlined,
  LightModeOutlined,
  MoneyOutlined,
  PointOfSaleOutlined,
  TokenOutlined,
} from "@mui/icons-material";
import logo from "../src/logo.jpeg";

const App = () => {
  const [user, setUser] = useState(null);
  const [userSummary, setUserSummary] = useState(null);

  const [ideas, setIdeas] = useState([]);
  const [cards, setCards] = useState([]);
  const [themeMode, setThemeMode] = useState("light");
  const [tabIndex, setTabIndex] = useState(0);
  const [userInfo, setUserInfo] = useState({ email: "", nickname: "" });
  const [infoSaved, setInfoSaved] = useState(false);
  const [loader, setLoader] = useState(true);
  const [userRole, setUserRole] = useState("");
  const [pendingPoints, setPendingPoints] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoader(true);
      const userDoc = await db.collection("users").doc(user?.uid).get();
      if (userDoc.exists) {
        const userData = userDoc.data();

        setUserSummary(userDoc.data());
        setUserRole(userData.role || ""); // Fetch and set the user's role
      }
      setLoader(false);
    };

    fetchUserData();
  }, [user]);

  const availableCards =
    userInfo?.cards?.map((card) => card.name).join(", ") ||
    "0";

    const checkPaymentStatus = async () => {
      try {
        const userId = auth.currentUser.uid;
  
        // Query the userPayments collection for pending transactions
        const q = query(
          collection(db, "userPayments"),
          where("userId", "==", userId),
          where("status", "==", "pending")
        );
  
        const querySnapshot = await getDocs(q);
  
        if (querySnapshot.empty) {
          return;
        }
  
        // Assuming there is only one pending transaction, otherwise you can handle multiple pending transactions
        const pendingTransaction = querySnapshot.docs[0];
  
        const transactionId = pendingTransaction.id;
  
        const functions = getFunctions();
        const checkPaymentStatusFunction = httpsCallable(
          functions,
          "checkPaymentStatus"
        );
  
        const result = await checkPaymentStatusFunction({ transactionId });
        const status = result.data.orderStatus;

        if (status === "Paid") {
          const userRef = doc(db, "users", userId);

          const userDoc = await db.collection("users").doc(userId).get();
          const userData = userDoc.data();
          const transactionData = pendingTransaction.data();
          const pendingPoints = transactionData.points;
          setPendingPoints(pendingPoints)
          await updateDoc(userRef, {
            points:userData.points +pendingPoints, // You can increment or set the count based on your logic
          });
    
  
          // Update the payment status from "pending" to "paid" in userPayments collection
  
  
          // Update payment status in Firestore
          await updateDoc(doc(db, "userPayments", transactionId), {
            status,
          });
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
      }
    };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = await db.collection("users").doc(user?.uid).get();
        checkPaymentStatus()
        if (userDoc.exists) {
          setUserInfo(userDoc.data());
          const name = userDoc.data().nickname ? true : false;
          const email = userDoc.data().email ? true : false;
          const userInfoExist = name && email ? true : false;
          setInfoSaved(userInfoExist);
        } else {
          setUserInfo({ email: "", nickname: "" });
        }
        setUser(user);
        setLoader(false);
      } else {
        setUser(null);

        setUserInfo({ email: "", nickname: "" });
      }
      setLoader(false);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    setLoader(true);
    if (user) {
      const unsubscribeIdeas = db.collection("ideas").onSnapshot((snapshot) => {
        const ideasData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setIdeas(ideasData);
        setLoader(false);
      });

      const unsubscribeCards = db.collection("cards").onSnapshot((snapshot) => {
        const cardsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCards(cardsData);
        setLoader(false);
      });

      return () => {
        unsubscribeIdeas();
        unsubscribeCards();
      };
    }
  }, [user]);

  const handleLogout = () => {
    auth.signOut();
  };

  const handleSaveUserInfo = async () => {
    const user = auth.currentUser;
    if (user) {
      setInfoSaved(true);
      await db
        .collection("users")
        .doc(user?.uid)
        .set(userInfo, { merge: true });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const theme = createTheme({
    palette: {
      mode: themeMode,
      primary: {
        main: "#5baede",
      },
      secondary: {
        main: "#c2185b",
      },
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container>
        <Paper
          style={{
            padding: 20,
            marginTop: 16,
            marginBottom: 16,
            borderRadius: 20,
            backgroundColor: themeMode === "light" ? "#fefaff" : null,
          }}
        >
          <Grid
            container
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              background: "rgba(255, 255, 255, 0.2)",
              backdropFilter: "blur(10px)",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              padding: "10px 20px",
              marginBottom: 5,
            }}
          >
            <Grid item>
              <img
                src={logo}
                width={"100%"}
                height={160}
                style={{ borderRadius: 20 }}
              />
            </Grid>

            <Grid
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              {user && (
                <IconButton onClick={() => {}} color="primary">
                  <TokenOutlined />
                  {userInfo.points +pendingPoints || 0}
                </IconButton>
              )}
              {themeMode === "light" ? (
                <IconButton onClick={() => setThemeMode("dark")}>
                  <DarkModeOutlined />
                </IconButton>
              ) : (
                <IconButton onClick={() => setThemeMode("light")}>
                  <LightModeOutlined />
                </IconButton>
              )}
              {user && (
                <IconButton onClick={handleLogout} color="secondary">
                  <ExitToAppOutlined />
                </IconButton>
              )}

              {user && <NotificationsPopup user={user} />}
            </Grid>
          </Grid>
          {loader ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : !user ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <LoginForm />
            </Box>
          ) : (
            <Box>
              {!infoSaved ? (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <TextField
                    fullWidth
                    label="Email"
                    value={userInfo.email}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, email: e.target.value })
                    }
                    sx={{ mt: 2 }}
                  />
                  <TextField
                    fullWidth
                    label="Nickname"
                    value={userInfo.nickname}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, nickname: e.target.value })
                    }
                    sx={{ mt: 2 }}
                  />
                  <Button
                    fullWidth
                    onClick={handleSaveUserInfo}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Box fullWidth>
                  <Box
                    sx={{
                      background:
                        themeMode === "light"
                          ? "#5baede"
                          : "rgba(255, 255, 255, 0.2)",
                      backdropFilter: "blur(10px)",
                      borderRadius: "10px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      padding: "10px 20px",
                      marginTop: 5,
                      color: "white",
                      marginBottom: 5,
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        gap: 1,
                      }}
                    >
                      <InfoOutlined /> Account Info
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                      Welcome {userInfo.nickname} 👏
                    </Typography>
                    <Divider
                      sx={{ my: 2, borderColor: "white", borderWidth: 1 }}
                    />

                    <Typography variant="h6" gutterBottom>
                      Ideas: {userInfo?.ideas?.length || 0}{" "}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      Selected Idea : {userInfo?.selectedIdeas?.length || 0}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      Available Cards: {availableCards}
                    </Typography>
                  </Box>
                  
                  <Tabs
                    centered={true}
                    value={tabIndex}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    onChange={handleTabChange}
                    sx={{ mt: 2 }}
                  >
                    <Tab tabIndex={0} label="My Ideas" />
                    <Tab tabIndex={1} label="Ideas" />
                    <Tab tabIndex={2} label="Cards" />
                    {(userRole === "admin" || userRole === "qa") && (
                      <Tab tabIndex={3} label="Pending Ideas" />
                    )}
                    {userRole === "admin" && <Tab tabIndex={4} label="Users" />}
                    <Tab  tabIndex={userRole !== "admin" ?4:5} label="Leaderboard" />
                    <Tab tabIndex={userRole !== "admin" ?5:6} label="Notifications" />
                    
                  </Tabs>

                  {tabIndex === 0 && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      width="100%"
                    >
                      <MyIdeasList ideas={ideas} user={user} />
                    </Box>
                  )}
                  {tabIndex === 1 && (
                    <Box fullWidth>
                      <IdeaForm user={user} />
                      <IdeaList
                        ideas={ideas.filter(
                          (idea) => idea.status === "approved"
                        )}
                        user={user}
                      />
                    </Box>
                  )}
                  {tabIndex === 2 && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <CardList cards={cards} user={user} />
                    </Box>
                  )}

                  {tabIndex === 3 &&
                    (userRole === "admin" || userRole === "qa") && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <PendingIdeaList ideas={ideas} user={user} />
                      </Box>
                    )}

                  {tabIndex === 4 && userRole === "admin" && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <UserActions />{" "}
                    </Box>
                  )}

                  {tabIndex === (userRole?(userRole !== "admin" ?4:5):3) && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Leaderboard />{" "}
                    </Box>
                  )}

                  {tabIndex ===(userRole?(userRole !== "admin" ?5:6):4) && <LogsList />}
                </Box>
              )}
            </Box>
          )}
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default App;
