import React, { useState } from "react";
import { db } from "../firebase";
import { Button, TextField, Box } from "@mui/material";

const IdeaForm = ({ user }) => {
  const [idea, setIdea] = useState("");
  const [desc, setDesc] = useState("");

  const [budget, setBudget] = useState("");

  const [clicked, setClicked] = useState(false);

  const handleSubmit = async (e) => {
    if (!clicked) {
      setClicked(true);
      return;
    }
    e.preventDefault();

    await db.collection("ideas").add({
      text: idea,
      userId: user.uid,
      name:idea,
      budget,
      status:"pending",
      description:desc,
      selectedBy: null,
      createdAt: new Date(),
    });
    setIdea("");
    setClicked(false);
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }} fullWidth>
      {clicked && (
        <>
          <TextField
            fullWidth
            label="Activity Name"
            value={idea}
            onChange={(e) => setIdea(e.target.value)}
          />
          <br />

          <TextField
            fullWidth
            id="filled-multiline-static"
            label="Description"
            multiline
            rows={4}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
          <br />
          <TextField
            fullWidth
            label="Budget."
            value={budget}
            onChange={(e) => setBudget(e.target.value)}
          />
        </>
      )}
      <Button
        fullWidth
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        sx={{ mt: 2, padding: 2, color: "white", fontWeight: "bold" }}
      >
     {clicked?"Submit Idea ":"New Idea"} 
      </Button>
    </Box>
  );
};

export default IdeaForm;
