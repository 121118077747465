// Leaderboard.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { db } from '../firebase'; // Adjust the import based on your project structure

const Leaderboard = () => {
  const [users, setUsers] = useState([]);
  const [ideas, setIdeas] = useState([]);

  useEffect(() => {
    const unsubscribeUsers = db.collection("users").onSnapshot((snapshot) => {
      const usersData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUsers(usersData);
    });

    const unsubscribeIdeas = db.collection("ideas").onSnapshot((snapshot) => {
      const ideasData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setIdeas(ideasData);
    });

    // Clean up the subscriptions
    return () => {
      unsubscribeUsers();
      unsubscribeIdeas();
    };
  }, []);

  const getApprovedIdeasCount = (userId) => {
    return ideas.filter(idea => idea.userId === userId && idea.status === 'approved').length;
  };

  const users1 = users.filter((user) => user.id !== "1");

  return (
    <Box display="flex" flexDirection="column" alignItems="center" sx={{ mt: 2 }}>
      <Typography variant="h4" gutterBottom>Leaderboard</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nickname</TableCell>
              <TableCell align="center">Points</TableCell>

              <TableCell align="center">Approved Ideas</TableCell>

              <TableCell align="center">Activity Date</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {users1.map((user) => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="row">
                  {user.nickname || user.name}
                </TableCell>
                <TableCell align="center">{user.points || 0}</TableCell>
                <TableCell align="center">{getApprovedIdeasCount(user.id)}</TableCell>
                <TableCell align="center">{user.activityDate}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Leaderboard;
