// UserActions.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { db } from "../firebase"; // Adjust the import based on your project structure
import { increment } from 'firebase/firestore';
const UserActions = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [memberVote, setMemberVote] = useState("");
  const [reduce, setReduce] = useState("");

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState({});

  useEffect(() => {
    const unsubscribeUsers = db.collection("users").onSnapshot((snapshot) => {
      const usersData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUsers(usersData);
    });

    // Clean up the subscription
    return () => unsubscribeUsers();
  }, []);

  const handleUserSelect = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleActionClick = (points, actionName) => {
    setConfirmAction({ points, actionName });
    setOpenConfirmDialog(true);
  };

  const handleConfirmAction = async () => {
    const userRef = db.collection("users").doc(selectedUser);

    try {
      await db.runTransaction(async (transaction) => {
        const userDoc = await transaction.get(userRef);

        if (!userDoc.exists) {
          throw new Error("User document does not exist!");
        }

        const newPoints = (userDoc.data().points || 0) + confirmAction.points;

        // Update the user's points
        if(confirmAction.actionName === "Package"){
          transaction.update(userRef, {
            packageCount: increment(1), // Decrease the packageCount by 1
          });
        }
        else{
          transaction.update(userRef, {
            points: newPoints,
          });
        }
     
      });
      setOpenConfirmDialog(false);
      alert(`${confirmAction.actionName} action successful!`);
    } catch (error) {
      console.error("Error in updating user points: ", error);
    }
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const users1 = users.filter((user) => user.id !== "1");


  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ mt: 2 }}
    >
      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel id="user-select-label">Select User</InputLabel>
        <Select
          labelId="user-select-label"
          value={selectedUser}
          onChange={handleUserSelect}
        >
          {users1.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.nickname}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedUser && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleActionClick(2, "Early Attends")}
            sx={{ mb: 1 }}
          >
            Early Attends
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleActionClick(2, "Helper")}
            sx={{ mb: 1 }}
          >
            Helper
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleActionClick(10, "Report Fraud")}
            sx={{ mb: 1 }}
          >
            Report Fraud
          </Button>

          <Button
          variant="contained"
          color="primary"
          onClick={() => handleActionClick(1, "Package")}
          sx={{ mb: 1 }}
        >
          Give 1 package
        </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleActionClick(1, "Clown")}
            sx={{ mb: 1 }}
          >
            Clown
          </Button>
          <TextField
            label="Member's Vote"
            value={memberVote}
            onChange={(e) => setMemberVote(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />

          {memberVote && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                handleActionClick(parseInt(memberVote), "Member Vote")
              }
              sx={{ mb: 1 }}
            >
              Submit
            </Button>
          )}

    
        </Box>
      )}
      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to perform the {confirmAction.actionName}{" "}
            action?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserActions;
