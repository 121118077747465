import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Divider,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Avatar,
} from "@mui/material";
import { format } from "date-fns";

const IdeaList = ({ ideas, user }) => {
  const [userPoints, setUserPoints] = useState(0);
  const [selectedIdeas, setSelectedIdeas] = useState([]);
  const [userNickname, setUserNickname] = useState("");
  const [loader, setLoader] = useState(true);
  const [userNicknames, setUserNicknames] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      setLoader(true);
      const userDoc = await db.collection("users").doc(user.uid).get();
      if (userDoc.exists) {
        const userData = userDoc.data();
        setUserPoints(userData.points || 0);
        setSelectedIdeas(userData.selectedIdeas || []);
        setUserNickname(userData.nickname || "");
      } else {
        await db
          .collection("users")
          .doc(user.uid)
          .set({ points: 0, selectedIdeas: [], nickname: "" });
      }
      setLoader(false);
    };

    const fetchUserNicknames = async () => {
      const usersSnapshot = await db.collection("users").get();
      const nicknames = {};
      usersSnapshot.forEach((doc) => {
        nicknames[doc.id] = doc.data().nickname || doc.data().name || "";
      });
      setUserNicknames(nicknames);
    };

    fetchUserData();
    fetchUserNicknames();
  }, [user]);

  const handleSelect = async (id) => {
    if (selectedIdeas.length < 3) {
      const ideaRef = db.collection("ideas").doc(id);
      const ideaDoc = await ideaRef.get();
      if (!ideaDoc.data().selectedBy) {
        setLoader(true);
        await ideaRef.update({
          selectedBy: user.uid,
          selectedByNickname: userNickname,
        });

        const newSelectedIdeas = [...selectedIdeas, id];
        setSelectedIdeas(newSelectedIdeas);
        await db
          .collection("users")
          .doc(user.uid)
          .update({ selectedIdeas: newSelectedIdeas });

        const ideaOwnerRef = db.collection("users").doc(ideaDoc.data().userId);
        const ideaOwnerDoc = await ideaOwnerRef.get();
        if (ideaOwnerDoc.exists) {
          const newPoints = (ideaOwnerDoc.data().points || 0) + 3;
          await ideaOwnerRef.update({ points: newPoints });
        }
        setLoader(false);
      } else {
        alert("Idea already selected");
      }
    } else {
      alert("You can only select up to 3 ideas");
    }
  };

  const handleDeselect = async (id) => {
    setLoader(true);
    const ideaRef = db.collection("ideas").doc(id);
    await ideaRef.update({
      selectedBy: null,
      selectedByNickname: null,
    });

    const newSelectedIdeas = selectedIdeas.filter(ideaId => ideaId !== id);
    setSelectedIdeas(newSelectedIdeas);
    await db
      .collection("users")
      .doc(user.uid)
      .update({ selectedIdeas: newSelectedIdeas });

    const ideaDoc = await ideaRef.get();
    const ideaOwnerRef = db.collection("users").doc(ideaDoc.data().userId);
    const ideaOwnerDoc = await ideaOwnerRef.get();
    if (ideaOwnerDoc.exists) {
      const newPoints = (ideaOwnerDoc.data().points || 0) - 3;
      await ideaOwnerRef.update({ points: newPoints });
    }
    setLoader(false);
  };

  if (loader)
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Idea List
      </Typography>
      <List>
        {ideas
          .filter((idea) => idea.userId !== user.uid)
          .map((idea) => (
            <Paper key={idea.id} sx={{ mb: 2, p: 2 ,overflowWrap:'break-word'}}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <Typography variant="h6">{idea.name}</Typography>
                  <Typography variant="body2">{idea.description}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Created by: {userNicknames[idea.userId] || idea.userId}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Status: {idea.status}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Created at: {format(idea.createdAt.toDate(), "PPpp")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} container direction="column" alignItems="flex-end" justifyContent="center">
                  <Avatar sx={{ mb: 1 }}>{idea.selectedByNickname ? idea.selectedByNickname.charAt(0) : 'A'}</Avatar>
                  <Typography variant="body2" color="textSecondary">
                    {idea.selectedBy ? `Selected by: ${idea.selectedByNickname}` : "Available"}
                  </Typography>
                  {!idea.selectedBy ? (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 1 }}
                      onClick={() => handleSelect(idea.id)}
                      disabled={selectedIdeas.length >= 3}
                    >
                      Select
                    </Button>
                  ) : idea.selectedBy === user.uid ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 1 }}
                      onClick={() => handleDeselect(idea.id)}
                    >
                      Deselect
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Paper>
          ))}
      </List>
    </Box>
  );
};

export default IdeaList;
