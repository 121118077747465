import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { db } from "../firebase"; // Adjust the import based on your project structure

const PendingIdeaList = ({ ideas, user }) => {
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [currentIdea, setCurrentIdea] = useState(null);
  const [rejectionReason, setRejectionReason] = useState("");

  const handleApprove = async (ideaId, userId) => {
    const ideaRef = db.collection("ideas").doc(ideaId);
    const userRef = db.collection("users").doc(userId);

    await db.runTransaction(async (transaction) => {
      const ideaDoc = await transaction.get(ideaRef);
      const userDoc = await transaction.get(userRef);

      if (!ideaDoc.exists || !userDoc.exists) {
        throw new Error("Document does not exist!");
      }

      const ideaData = ideaDoc.data();
      const userData = userDoc.data();

      const newPoints = (userData.points || 0) + 5;

      // Fetch the user who created the idea
      const ideaOwnerRef = db.collection("users").doc(ideaData.userId);
      const ideaOwnerDoc = await transaction.get(ideaOwnerRef);

      if (!ideaOwnerDoc.exists) {
        throw new Error("Idea owner does not exist!");
      }

      const ideaOwnerData = ideaOwnerDoc.data();

      // if(ideaOwnerData.role !=="admin" ||  ideaOwnerData.role !=="qa" ){
      //   return alert("Fuck off ");
      // }

      // Update the idea status
      transaction.update(ideaRef, {
        status: "approved",
      });

      // Update the user's points
      transaction.update(userRef, {
        points: newPoints,
      });

      // Add log entry
      await db.collection("logs").add({
        userId: userId,
        ideaId: ideaId,
        action: "approved",
        date: Date.now(),
        logEntry: `--- ${ideaData.name} --- idea approved for ${ideaOwnerData.nickname} ✅`,
      });
    });
  };

  const handleRejectClick = (idea) => {
    setCurrentIdea(idea);
    setOpenRejectDialog(true);
  };

  const handleReject = async () => {
    if (currentIdea) {
      // Update the idea status
      await db.collection("ideas").doc(currentIdea.id).update({
        status: "rejected",
        rejectionReason: rejectionReason,
      });

      // Fetch the idea owner's data
      const ideaOwnerRef = db.collection("users").doc(currentIdea.userId);
      const ideaOwnerDoc = await ideaOwnerRef.get();
      const ideaOwnerData = ideaOwnerDoc.data();

      // if(ideaOwnerData.role !=="admin" ||  ideaOwnerData.role !=="qa" ){
      //   return alert("Fuck off ");
      // }

      // Add log entry
      await db.collection("logs").add({
        userId: currentIdea.userId,
        ideaId: currentIdea.id,
        action: "rejected",
        date: Date.now(),
        logEntry: `--- ${currentIdea.name} --- idea is rejected for ${ideaOwnerData.nickname} ❌`,
      });

      // Reset the rejection dialog
      setOpenRejectDialog(false);
      setRejectionReason("");
      setCurrentIdea(null);
    }
  };

  const handleClose = () => {
    setOpenRejectDialog(false);
    setRejectionReason("");
    setCurrentIdea(null);
  };




  // Filter only pending ideas
  const pendingIdeas = ideas.filter(
    (idea) => idea.status === "pending" && idea.userId !== user.uid
  );

  return (
    <div style={{ width: "100%" }}>
      {pendingIdeas.map((idea) => (
        <Box
          key={idea.id}
          sx={{
            mb: 2,
            p: 2,
            border: "1px solid #ccc",
            borderRadius: "8px",
            overflowWrap: "break-word",
            width: "100%",
          }}
        >
          <Typography variant="h6">{idea.name}</Typography>
          <Typography>{idea.description}</Typography>
          <Typography>Budget: {idea.budget}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleApprove(idea.id, idea.userId, idea)}
            sx={{ mr: 2 }}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleRejectClick(idea)}
          >
            Reject
          </Button>
        </Box>
      ))}

      <Dialog open={openRejectDialog} onClose={handleClose}>
        <DialogTitle>Reject Idea</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Rejection Reason"
            type="text"
            multiline
            fullWidth
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleReject} color="secondary">
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PendingIdeaList;
