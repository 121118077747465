import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyChYEQyU2VswCWWYBRIHY5LuTGhwZN4Ujg",
  authDomain: "idea-system-af5ae.firebaseapp.com",
  projectId: "idea-system-af5ae",
  storageBucket: "idea-system-af5ae.appspot.com",
  messagingSenderId: "570836516146",
  appId: "1:570836516146:web:a4df463ca8bbdcedfcab2b",
  measurementId: "G-24GDGQZ0QD",
};

export const firbaseapp = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
auth.languageCode = "it";

export const db = firebase.firestore();
