import React, { useState, useRef } from "react";
import $ from "jquery";
import "jquery-ui/ui/widgets/dialog";
import "./CardSelector.css";
import { Button } from "@mui/material";
import img from "../../src/pack.jpeg";

const CardSelector = ({ cardsWithProbability, onClose }) => {
  // Card list with probabilities

  const cardListRef = useRef(null);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCardIndex] = useState(null);
  const [hide, setHide] = useState(false);
  const random = (min, max) => Math.floor(Math.random() * (max - min) + min);

  // Function to select a card based on probability
  const selectCardBasedOnProbability = () => {
    const totalProbability = cardsWithProbability.reduce(
      (acc, card) => acc + card.probability,
      0
    );
    let randomValue = Math.random() * totalProbability;

    for (let i = 0; i < cardsWithProbability.length; i++) {
      if (randomValue < cardsWithProbability[i].probability) {
        return i;
      }
      randomValue -= cardsWithProbability[i].probability;
    }
  };

  const reset = () => {
    $(cardListRef.current).empty(); // Clear previous cards

    for (let i = 0; i < 210; i++) {
      const selectedCardIndex = selectCardBasedOnProbability();
      const selectedCard = cardsWithProbability[selectedCardIndex];
      const element = `
        <div class="card" style="background-color:#fff" data-rarity="${selectedCard.rarity}" id="itemNumber${i}">
          <img src="${selectedCard.url}" alt="${selectedCard.rarity} item" />
        </div>`;
      $(cardListRef.current).append(element);
    }
    $(".card").first().css("margin-left", -1000);
  };

  const openCase = () => {
    reset();
    setHide(true);
    const cardWidth = 108; // Width of each card in pixels
    const visibleContainerWidth = 250; // Width of the visible container where the cards are displayed
    const pointerPosition = visibleContainerWidth / 2; // Center the pointer in the container

    // Calculate the offset needed to align the selected card with the pointer
    const selectedCardIndex = selectCardBasedOnProbability();
    setSelectedCardIndex(cardsWithProbability[selectedCardIndex]);
    // Calculate the total distance to move to align the selected card perfectly under the pointer
    const totalScrollDistance = Math.abs(
      selectedCardIndex * cardWidth - pointerPosition
    ); // Ensure positive value

    let startTime;
    const duration = 5000; // 5 seconds for the entire animation

    // Custom easing function for a fast start and slow stop (easeInOutCubic)
    const easingFunction = (t) =>
      t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;

    function animateScroll(timestamp) {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;

      // Calculate the progress (max value is 1, capping it at 1)
      const progress = Math.min(elapsed / duration, 1);

      // Apply easing to the progress
      const easedProgress = easingFunction(progress);

      // Calculate the current scroll position based on eased progress
      const currentScroll = easedProgress * totalScrollDistance;

      // Apply the calculated margin-left to animate the cards
      $(".card")
        .first()
        .css("margin-left", -currentScroll + "px");

      // Continue the animation until the duration is complete
      if (elapsed < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        // Animation complete, align the selected card
        const rewardElement = $(`#itemNumber${selectedCardIndex}`);

        const src = rewardElement.find('img').attr('src');
        const matchingCard = cardsWithProbability.find(card => card.url === src);

        // Highlight the selected card
        rewardElement.css({ background: "linear-gradient(#00bf09, #246b27)" });

        // Display the result in the dialog

        onClose(matchingCard)
        // Use jQuery UI's dialog to show the result
      }
    }
    // Start the animation
    requestAnimationFrame(animateScroll);
  };

  return (
    <div id="unbox-area">
      <div className="arrow-down"></div>
      <div id="cardList" ref={cardListRef}>
        <img src={img} width={200} height={100} />
      </div>
      { !hide && (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ color: "white", marginTop: 3, fontWeight: "bold" }}
          onClick={openCase}
        >
          Open Your Package
        </Button>
      )}
    </div>
  );
};

export default CardSelector;
